import React, { useState } from 'react';
const CookieDisclosure = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleGotIt = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-disclosure">
      <div className="cookie-disclosure-content">
        <p>This website doesn't use cookies to track you or store your data. We respect your privacy.</p>
        <div className="cookie-disclosure-buttons">
          <button onClick={handleGotIt} className="got-it-btn">Got it</button>
          <button onClick={handleClose} className="close-btn">✕</button>
        </div>
      </div>
    </div>
  );
};

export default CookieDisclosure;
